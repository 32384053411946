<template>
  <section class="container px-3 pt-24 pb-16 mx-auto sm:px-0">
    <p
      class="
        mb-2
        text-sm
        font-semibold
        leading-6
        tracking-wider
        text-justify text-blue-gray-800
      "
    >
      SGUniStats | Legal
    </p>
    <h1 class="mb-10 text-2xl font-bold text-blue-gray-800">
      Terms and Conditions
    </h1>

    <div class="leading-7 tracking-tight text-justify">
      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          OVERVIEW
        </h2>

        <p class="mb-3">
          This website is operated by SGUniStats. Throughout the site, the terms
          “we”, “us” and “our” refer to SGUniStats. SGUniStats offers this
          website, including all information, tools and services available from
          this site to you, the user, conditioned upon your acceptance of all
          terms, conditions, policies and notices stated here.
        </p>

        <p class="mb-3">
          By visiting our site, you engage in our “Service” and agree to be
          bound by the following terms and conditions (“Terms of Service”,
          “Terms”), including those additional terms and conditions and policies
          referenced herein and/or available by hyperlink. These Terms of
          Service apply to all users of the site. Please read these Terms of
          Service carefully before accessing or using our website. By accessing
          or using any part of the site, you agree to be bound by these Terms of
          Service. If you do not agree to all the terms and conditions of this
          agreement, then you may not access the website or use any services. If
          these Terms of Service are considered an offer, acceptance is
          expressly limited to these Terms of Service.
        </p>

        <p class="mb-3">
          Any new features or tools which are added to the website shall also be
          subject to the Terms of Service. You can review the most current
          version of the Terms of Service at any time on this page. We reserve
          the right to update, change or replace any part of these Terms of
          Service by posting updates and/or changes to our website. It is your
          responsibility to check this page periodically for changes. Your
          continued use of or access to the website following the posting of any
          changes constitutes acceptance of those changes.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 1 - GENERAL CONDITIONS
        </h2>

        <p class="mb-3">
          We reserve the right to refuse service to anyone for any reason at any
          time.
        </p>

        <p class="mb-3">
          You understand that your content, may be transferred unencrypted and
          involve (a) transmissions over various networks; and (b) changes to
          conform and adapt to technical requirements of connecting networks or
          devices.
        </p>

        <p class="mb-3">
          You agree not to reproduce, duplicate, copy, sell, resell or exploit
          any portion of the Service, use of the Service, or access to the
          Service or any contact on the website through which the service is
          provided, without express written permission by us.
        </p>

        <p class="mb-3">
          The headings used in this agreement are included for convenience only
          and will not limit or otherwise affect these Terms.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 2 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
        </h2>

        <p class="mb-3">
          We are not responsible if information made available on this site is
          not accurate, complete or current. The material on this site is
          provided for general information only and should not be relied upon or
          used as the sole basis for making decisions without consulting
          primary, more accurate, more complete or more timely sources of
          information. Any reliance on the material on this site is at your own
          risk.
        </p>

        <p class="mb-3">
          This site may contain certain historical information. Historical
          information, necessarily, is not current and is provided for your
          reference only. We reserve the right to modify the contents of this
          site at any time, but we have no obligation to update any information
          on our site. You agree that it is your responsibility to monitor
          changes to our site.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 3 - MODIFICATIONS TO THE SERVICE
        </h2>

        <p class="mb-3">
          We reserve the right at any time to modify or discontinue the Service
          (or any part or content thereof) without notice at any time.
        </p>

        <p class="mb-3">
          We shall not be liable to you or to any third-party for any
          modification, suspension or discontinuance of the Service.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 4 - TOOLS
        </h2>

        <p class="mb-3">
          We may provide you with access to third-party tools over which we
          neither monitor nor have any control nor input.
        </p>

        <p class="mb-3">
          You acknowledge and agree that we provide access to such tools ”as is”
          and “as available” without any warranties, representations or
          conditions of any kind and without any endorsement. We shall have no
          liability whatsoever arising from or relating to your use of
          third-party tools.
        </p>

        <p class="mb-3">
          Any use by you of tools offered through the site is entirely at your
          own risk and discretion and you should ensure that you are familiar
          with and approve of the terms on which tools are provided by the
          relevant third-party provider(s).
        </p>

        <p class="mb-3">
          We may also, in the future, offer new services and/or features through
          the website (including, the release of new tools and resources). Such
          new features and/or services shall also be subject to these Terms of
          Service.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 5 - THIRD-PARTY LINKS
        </h2>

        <p class="mb-3">
          Certain content, services available via our Service may include
          materials from third-parties.
        </p>

        <p class="mb-3">
          Third-party links on this site may direct you to third-party websites
          that are not affiliated with us. We are not responsible for examining
          or evaluating the content or accuracy and we do not warrant and will
          not have any liability or responsibility for any third-party materials
          or websites, or for any other materials, products, or services of
          third-parties.
        </p>

        <p class="mb-3">
          We are not liable for any harm or damages related to the use of
          services, resources, or content in connection with any third-party
          websites. Please review carefully the third-party's policies and
          practices and make sure you understand them. Complaints, claims,
          concerns, or questions regarding third-party products should be
          directed to the third-party.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 6 - ERRORS, INACCURACIES AND OMISSIONS
        </h2>

        <p class="mb-3">
          Occasionally there may be information on our site or in the Service
          that contains typographical errors, inaccuracies or omissions. We
          reserve the right to correct any errors, inaccuracies or omissions,
          and to change or update information if any information in the Service
          or on any related website is inaccurate at any time without prior
          notice.
        </p>

        <p class="mb-3">
          We undertake no obligation to update, amend or clarify information in
          the Service or on any related website, including without limitation
          except as required by law. No specified update or refresh date applied
          in the Service or on any related website, should be taken to indicate
          that all information in the Service or on any related website has been
          modified or updated.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 7 - PROHIBITED USES
        </h2>

        <p class="mb-3">
          In addition to other prohibitions as set forth in the Terms of
          Service, you are prohibited from using the site or its content:
        </p>

        <ul class="ml-2 space-y-2">
          <li>(a) for any unlawful purpose;</li>

          <li>
            (b) to solicit others to perform or participate in any unlawful
            acts;
          </li>

          <li>
            (c) to violate any international, federal, provincial or state
            regulations, rules, laws, or local ordinances;
          </li>

          <li>
            (d) to infringe upon or violate our intellectual property rights or
            the intellectual property rights of others;
          </li>

          <li>
            (e) to harass, abuse, insult, harm, defame, slander, disparage,
            intimidate, or discriminate based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin, or disability;
          </li>

          <li>(f) to submit false or misleading information;</li>

          <li>
            (g) to upload or transmit viruses or any other type of malicious
            code that will or may be used in any way that will affect the
            functionality or operation of the Service or of any related website,
            other websites, or the Internet;
          </li>

          <li>(h) to collect or track the personal information of others;</li>

          <li>(i) to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>

          <li>(j) for any obscene or immoral purpose; or</li>

          <li>
            (k) to interfere with or circumvent the security features of the
            Service or any related website, other websites, or the Internet. We
            reserve the right to terminate your use of the Service or any
            related website for violating any of the prohibited uses.
          </li>
        </ul>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 8 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
        </h2>

        <p class="mb-3">
          We do not guarantee, represent or warrant that your use of our service
          will be uninterrupted, timely, secure or error-free.
        </p>

        <p class="mb-3">
          We do not warrant that the results that may be obtained from the use
          of the service will be accurate or reliable.
        </p>

        <p class="mb-3">
          You agree that from time to time we may remove the service for
          indefinite periods of time or cancel the service at any time, without
          notice to you. You expressly agree that your use of, or inability to
          use, the service is at your sole risk. The services delivered to you
          through the service are (except as expressly stated by us) provided
          'as is' and 'as available' for your use, without any representation,
          warranties or conditions of any kind, either express or implied. In no
          case shall SGUniStats be liable for any injury, loss, claim, or any
          direct, indirect, incidental, punitive, special, or consequential
          damages of any kind, including, without limitation lost profits, lost
          revenue, lost savings, loss of data, replacement costs, or any similar
          damages, arising from your use of any of the service procured using
          the service, or for any other claim related in any way to your use of
          the service, including, but not limited to, any errors or omissions in
          any content, or any loss or damage of any kind incurred as a result of
          the use of the service or any content (or product) posted,
          transmitted, or otherwise made available via the service, even if
          advised of their possibility. Because some states or jurisdictions do
          not allow the exclusion or the limitation of liability for
          consequential or incidental damages, in such states or jurisdictions,
          our liability shall be limited to the maximum extent permitted by law.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 9 - INDEMNIFICATION
        </h2>

        <p class="mb-3">
          You agree to indemnify, defend and hold harmless SGUniStats from any
          claim or demand, including reasonable attorneys’ fees, made by any
          third-party due to or arising out of your breach of these Terms of
          Service or the documents they incorporate by reference, or your
          violation of any law or the rights of a third-party.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 10 - SEVERABILITY
        </h2>

        <p class="mb-3">
          In the event that any provision of these Terms of Service is
          determined to be unlawful, void or unenforceable, such provision shall
          nonetheless be enforceable to the fullest extent permitted by
          applicable law, and the unenforceable portion shall be deemed to be
          severed from these Terms of Service, such determination shall not
          affect the validity and enforceability of any other remaining
          provisions.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 11 - TERMINATION
        </h2>

        <p class="mb-3">
          The obligations and liabilities of the parties incurred prior to the
          termination date shall survive the termination of this agreement for
          all purposes.
        </p>

        <p class="mb-3">
          These Terms of Service are effective unless and until terminated by
          either you or us. You may terminate these Terms of Service at any time
          by notifying us that you no longer wish to use our Services, or when
          you cease using our site. If in our sole judgment you fail, or we
          suspect that you have failed, to comply with any term or provision of
          these Terms of Service, we also may terminate this agreement at any
          time without notice and you will remain liable for all amounts due up
          to and including the date of termination; and/or accordingly may deny
          you access to our Services (or any part thereof).
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 12 - ENTIRE AGREEMENT
        </h2>

        <p class="mb-3">
          The failure of us to exercise or enforce any right or provision of
          these Terms of Service shall not constitute a waiver of such right or
          provision.
        </p>

        <p class="mb-3">
          These Terms of Service and any policies or operating rules posted by
          us on this site or in respect to The Service constitutes the entire
          agreement and understanding between you and us and govern your use of
          the Service, superseding any prior or contemporaneous agreements,
          communications and proposals, whether oral or written, between you and
          us (including, but not limited to, any prior versions of the Terms of
          Service). Any ambiguities in the interpretation of these Terms of
          Service shall not be construed against the drafting party.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 13 - GOVERNING LAW
        </h2>

        <p class="mb-3">
          These Terms of Service and any separate agreements whereby we provide
          you Services shall be governed by and construed in accordance with the
          laws of Singapore.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          SECTION 14 - CHANGES TO TERMS OF SERVICE
        </h2>
        <p class="mb-3">
          You can review the most current version of the Terms of Service at any
          time at this page.
        </p>

        <p class="mb-3">
          We reserve the right, at our sole discretion, to update, change or
          replace any part of these Terms of Service by posting updates and
          changes to our website. It is your responsibility to check our website
          periodically for changes. Your continued use of or access to our
          website or the Service following the posting of any changes to these
          Terms of Service constitutes acceptance of those changes.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-2
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          More
        </h2>
        <router-link
          class="underline sm:hover:text-sky-800"
          :to="{ name: 'privacy' }"
          >Privacy Policy</router-link
        >
      </div>
    </div>
  </section>
</template>
